.Toastify__toast-body {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px;
}

.Toastify__toast-icon {
    margin-right:0;
    width: fit-content;
    max-width: 24px;
    opacity: 0.5;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    justify-content: flex-start;
    margin-left: -28px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 28px; /* gutter size */
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 28px;
}

.masonry-item{
    /*width: calc(100% / 3 - 14px);*/
    margin-bottom: 24px;
}

.progress-bar {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    height: 2px;
    background: gray;
    transform-origin: 0;
    z-index: 99999;
}